import { useCallback, useState } from "react";

import { ChartTypeLabels } from "constants/chart.constants";
import {
  applyScreenshotSettings,
  getChartTypeAlias,
  getDatabaseRateUnits,
  getProductType,
  productStringToProductTypeEnum
} from "utils";
import { addForecastSeries } from "utils/arps/forecastSeriesHelpers";

import { useUserSettings } from "components/user/hooks";

import { ArpsSegmentToSegmentDto as arpsSegmentToSegmentDto } from "../../arps/utils/arpsUtils";
import { IMultiphaseChartSettings } from "../models/shared.models";
import { getEchartOptionsFromResult } from "../util";

/**
 * @param options: Echarts options
 * @param isUsingNewData: Used to differentiate between creating a new chart, and setting options to an existing chart
 * @param data: Backend chart series data
 * @param notMerge: From echarts: notMerge Optional. Whether not to merge with previous option. false by default, means merge, see more details in Component Merging Modes. If true, all of the current components will be removed and new components will be created according to the new option.
 */
type SetOptionsParamsT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  isUsingNewData: boolean;
  notMerge?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
};
interface ITypeWellData {
  length?: number;
  stage?: number;
  stage_spacing?: number;
  proppant?: number;
  proppant_intensity?: number;
  additional_data?: { field: string; value: number }[];
}

//Todo: Param typing
const useChartOptionSetter = (
  chartRef,
  preset,
  chartSetting: IMultiphaseChartSettings,
  screenshot,
  legend,
  axisMinMax,
  arpsWasm: typeof import("wasm/arps")
) => {
  // Determined after created options
  const [hasChartData, setHasChartData] = useState(true);
  const [options, setCurrentOptions] = useState(undefined);
  const [defaultOptions, setDefaultOptions] = useState(undefined);

  const { userAbbreviations } = useUserSettings();
  const abbreviations = userAbbreviations?.abbreviations ?? [];

  const setOptions = useCallback(
    (params: SetOptionsParamsT) => {
      setHasChartData(true);

      if (chartRef) {
        if (
          params.options?.series?.length === 0 ||
          (params.isUsingNewData && !params.data)
        ) {
          setHasChartData(false);
        }

        setCurrentOptions(params.options);

        chartRef.setOption(params.options, params?.notMerge ?? false);
        chartRef.resize();
      }
    },
    [chartRef]
  );

  const createAndSetOptionsToChart = useCallback(
    (data) => {
      const initOptions = getEchartOptionsFromResult(
        preset,
        data,
        chartRef,
        chartSetting,
        axisMinMax,
        legend
      );

      processChartForecast(preset, chartSetting, data, initOptions);

      setOptions({
        options: initOptions,
        isUsingNewData: true,
        data,
        // Usage here will reset the zoom to 0 when selecting a new well, and discard the past options
        notMerge: true
      });

      setDefaultOptions(initOptions);
    },
    [chartRef, chartSetting, preset, setOptions, legend, axisMinMax]
  );

  const setOptionsToChart = useCallback(
    (options) => {
      if (chartRef && options) {
        const newOptions =
          screenshot?.visible && screenshot?.preset
            ? applyScreenshotSettings(options, chartRef, screenshot.preset, abbreviations)
            : options;

        if (chartSetting?.debugMode) {
          // eslint-disable-next-line no-console
          console.log(`option=${JSON.stringify(newOptions)}`);
        }

        setOptions({ options: newOptions, isUsingNewData: false });
      }
    },
    [
      chartRef,
      screenshot?.preset,
      screenshot?.visible,
      chartSetting?.debugMode,
      setOptions,
      abbreviations
    ]
  );

  const processChartForecast = (preset, chartSetting, data, options) => {
    if (!arpsWasm || !preset || !data?.segments || !data?.constants) {
      return;
    }

    const chartTypeString = getChartTypeAlias(preset.chartType) as ChartTypeLabels;
    const chartType =
      chartTypeString.indexOf("Time") >= 0 || chartTypeString.indexOf("Date") >= 0
        ? ChartTypeEnum.Time
        : ChartTypeEnum.Cum;
    const duplicatedDetector = [];
    const segments = [];

    // Make sure to ignore duplicated segments returned from API
    data.segments.forEach((s) => {
      const key = s.product + s.df + s.di + s.qf + s.qi + s.n;

      if (!duplicatedDetector.includes(key)) {
        duplicatedDetector.push(key);
        segments.push(
          Object.assign({}, s, {
            b: s.n,
            // TODO: standardize date time format from API
            startDate: s.startDate + "Z",
            endDate: s.endDate + "Z"
          })
        );
      }
    });

    // TODO: switch back to this after API is fixed from the duplicated issue
    // const segments = (data.segments ?? []).map((seg) =>
    //   Object.assign({}, seg, {
    //     b: seg.n,
    //     // TODO: standardize date time format from API
    //     startDate: seg.startDate + "Z",
    //     endDate: seg.endDate + "Z"
    //   })
    // );

    const dto = arpsSegmentToSegmentDto(arpsWasm, segments);

    if (dto.length == 0) {
      return;
    }

    const forecastConstantsWithUnit = [];

    // Make sure to ignore duplicated constants returned from API
    data.constants.forEach((c) => {
      const key = c.productId + c.constantValue + chartSetting.objectId;

      if (!duplicatedDetector.includes(key)) {
        duplicatedDetector.push(key);
        forecastConstantsWithUnit.push(
          Object.assign({}, c, {
            product: c.productId,
            value: c.constantValue,
            uniqueId: c.objectId,
            unit: getDatabaseRateUnits(productStringToProductTypeEnum(c.productId))
          })
        );
      }
    });

    // TODO: switch back to this after API is fixed from the duplicated issue
    // const forecastConstantsWithUnit = (data.constants ?? []).map((fconst) =>
    //   Object.assign({}, fconst, {
    //     product: fconst.productId,
    //     value: fconst.constantValue,
    //     uniqueId: fconst.objectId,
    //     unit: getDefaultRateUnits(productStringToProductTypeEnum(fconst.productId))
    //   })
    // );

    try {
      const normalizeBy = Object.assign(
        {
          field: chartSetting?.normalizeBy?.field ?? "",
          unit: chartSetting?.normalizeBy?.unit ?? "",
          per: chartSetting?.normalizeBy?.per ?? 1,
          display: chartSetting?.normalizeBy?.display ?? "",
          useMultilinearNormalization:
            chartSetting?.normalizeBy?.useMultilinearNormalization ?? false,
          threshold: chartSetting?.normalizeBy?.threshold ?? 0,
          lowerScalar: chartSetting?.normalizeBy?.lowerScalar ?? 1,
          higherScalar: chartSetting?.normalizeBy?.higherScalar ?? 1
        },
        {
          normalize: chartSetting?.useNormalizeBy ?? false,
          normalizeTypeWell: false
        }
      );

      let productCum = data?.productCumulativeValues;
      if (chartSetting.backfit) {
        productCum = data?.productCumulativeValues;
      }
      for (const series of options.series) {
        if (!series.name.includes("Forecast") && series.data.length > 0) {
          const productName = series.name;
          const productType = getProductType(productName);
          const productKey = productType.key;
          // convert cum to negative since backfit will go backwards to 0
          // time series is in months, so we can use the number of length of the series as each represents a month
          productCum[productKey] =
            chartType == ChartTypeEnum.Cum
              ? -series.data[series.data.length - 1][0] * 1000.0
              : series.data.length;
        }
      }
      const backfit = chartSetting.backfit
        ? arpsWasm.getBackfit(
            dto,
            forecastConstantsWithUnit,
            productCum, // cum will be normalized already, will be divided by the normalize value to get proper cum in arps_wasm
            chartType,
            normalizeBy,
            {
              length: 0,
              stage: 0,
              stage_spacing: 0,
              proppant: 0,
              proppant_intensity: 0,
              additional_data: []
            } as ITypeWellData,
            data.normalizeData
          )
        : [];
      const updatedSeries = [];

      options.series.forEach((ser) => {
        if (ser.name.includes("Forecast")) {
          if (chartSetting.forecast) {
            updatedSeries.push(ser);
          }
        } else {
          updatedSeries.push(ser);

          if (chartSetting.forecast && chartSetting.backfit) {
            const serData = data.series.find((s) => s.label == ser.name);
            const productType = getProductType(serData.label);

            for (const series of backfit) {
              const backfitSeries = addForecastSeries(
                ser,
                preset,
                series,
                productType,
                true
              );
              if (backfitSeries) updatedSeries.push(backfitSeries);
            }
          }
        }
      });

      options.series = updatedSeries;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return {
    hasChartData,
    createAndSetOptionsToChart,
    setOptionsToChart,
    options,
    defaultOptions
  };
};

enum ChartTypeEnum {
  Cum = 0,
  Time = 1
}

export default useChartOptionSetter;
