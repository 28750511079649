// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import {
  BaseSyntheticEvent,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import { mdiCalculator } from "@mdi/js";
import { Input, InputRef } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { UserArpsItem } from "models/UserArpsModel";

interface InputWihUnitModel {
  header?: string;
  unit: string;
  type: string;
  value: number;
  step?: number;
  disabled?: boolean;
  title?: string;
  min?: number;
  globalRef?: MutableRefObject<MutableRefObject<InputRef>[]>;
  handleReload?: (item?: UserArpsItem) => void;
  handleReset?: () => void;
  handleSave?: (item?: UserArpsItem) => void;
  onChange?: (value?: number) => void;
  isCalculated?: boolean;
  onBlur?: () => void;
}

export default function ArpInputField({
  header,
  unit,
  type,
  value,
  step,
  isCalculated,
  disabled,
  min,
  globalRef,
  handleReload,
  handleReset,
  handleSave,
  onBlur,
  onChange
}: InputWihUnitModel) {
  const [ownedValue, setOwnedValue] = useState<string>(value?.toString());
  const [originalValue] = useState<string>(value?.toString());
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  // indicates if a user is inputting their own value
  const [userInputting, setUserInputting] = useState<boolean>(false);
  const inputRef = useRef(null);

  function setOwnedValueWrapper(value) {
    if (header.includes("Di (tan)") && parseFloat(value) >= 100) {
      setOwnedValue("99");
    } else if (min != null && parseFloat(value) < min) {
      setOwnedValue(min.toString());
    } else {
      setOwnedValue(value);
    }
  }

  useEffect(() => {
    setOwnedValueWrapper(value?.toString());
  }, [value]);

  // this useEffect handles the case where the value is updated from outside,
  // including after changes to the input propogate through after arrow keys or scrolling,
  // and keeps the input text selected after the value is updated
  useEffect(() => {
    if (hasFocus && Number(ownedValue) === value && !userInputting) {
      inputRef.current.select();
    }
  }, [ownedValue]);

  function getIncrement(
    value: string,
    useFineGrainedStep: boolean,
    useAdaptiveScrolling: boolean
  ) {
    const numericValue = parseFloat(value);

    let increment = step;

    if (useAdaptiveScrolling) {
      const sigFig = Math.pow(
        10,
        Math.max(1, Math.abs(numericValue).toFixed(0).length - 1)
      );
      increment = 0.1 * sigFig;
      if (Math.abs(step - 0.1) < 0.000000001) {
        increment = 0.01 * sigFig;
      }
      if (useFineGrainedStep) {
        increment = 0.01 * sigFig;
      }
    } else if (useFineGrainedStep) {
      increment *= 0.1;
    }

    return { numericValue, increment };
  }

  const handleWheelEvent = useCallback(
    //need callback here because addEventListener has closed on the old values
    //need to change handleWheelEvent when onChange has been changed
    (event) => {
      if (!hasFocus) {
        //don't have focus so don't handle wheel events
        return;
      }

      event.preventDefault();
      const useFineGrainedStep =
        event.shiftKey && (header.startsWith("D") || header.startsWith("Q"));
      // eslint-disable-next-line prefer-const
      let { numericValue, increment } = getIncrement(
        event.target.value,
        useFineGrainedStep,
        header.startsWith("D") || header.startsWith("Q")
      );

      const e_delta = event.deltaY || -event.wheelDelta || event.detail;
      const delta = (e_delta && (e_delta >> 10 || 1)) || 0;
      if (delta < 0) {
        numericValue = addDecimal(numericValue, increment);
      } else if (delta > 0) {
        numericValue = addDecimal(numericValue, -increment);
      } else {
        return;
      }
      if (header.includes("Di (tan)") && numericValue >= 100.0) {
        numericValue = 99.0;
      }
      const val = numericValue.toFixed(6);
      setOwnedValueWrapper(val);
      changeDebounceRef.current(() => onChange && onChange(Number(val)));
      setUserInputting(false);
    },
    [hasFocus, step, onChange]
  );

  const handleValueChanged = (event) => {
    setOwnedValueWrapper(event.target.value);
  };

  const changeDebounceRef = useRef(
    _debounce((fn) => {
      fn && fn();
    }, 600)
  );
  const shortChangeDebounceRef = useRef(
    _debounce((fn) => {
      fn && fn();
    }, 1)
  );
  useEffect(() => {
    const ref = inputRef.current;
    if (!ref?.input) {
      return;
    }

    if (
      globalRef?.current &&
      globalRef.current.findIndex((ref) => ref?.current === inputRef.current) === -1
    ) {
      globalRef.current[globalRef.current.length] = inputRef;
    }

    ref?.input.addEventListener("wheel", handleWheelEvent, { passive: false });
    return () => {
      if (!ref?.input) {
        return;
      }
      ref?.input.removeEventListener("wheel", handleWheelEvent);
    };
  }, [inputRef, handleWheelEvent]);

  const handleFocus = (event) => {
    setHasFocus(true);
    event.target.select();
  };
  const handleFocusLost = () => {
    setHasFocus(false);
    if (ownedValue === "") {
      setOwnedValue(String(0));
      onChange && onChange(0);
    }
    if (onBlur) {
      onBlur();
    }
  };
  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        return;
      }
      event.preventDefault();
      const synth = event as BaseSyntheticEvent;
      const value = synth.target.value;

      let num = Number(parseFloat(value).toFixed(6));
      if (isNaN(num) || !isFinite(num)) {
        return;
      }
      if (header.includes("Di (tan)") && num >= 100.0) {
        num = 99.0;
      }

      if (event.key === "Escape") {
        // Reset input to original value
        setOwnedValueWrapper(originalValue);
        changeDebounceRef.current(() => onChange && onChange(Number(originalValue)));
        return;
      }

      setOwnedValueWrapper(synth.target.value);
      changeDebounceRef.current(() => onChange && onChange(num));

      if (event.key === "Enter" || event.key === "ArrowDown" || event.key === "ArrowUp") {
        handleFocus(event);
        setUserInputting(false);
        return;
      }

      setUserInputting(true);
    },
    [onChange]
  );

  function addDecimal(number, decimalToAdd) {
    const precision = 10; // Set the desired precision
    const multiplier = Math.pow(10, precision);
    return (number * multiplier + decimalToAdd * multiplier) / multiplier;
  }

  const isNextField = (fieldName, keyBinding) => {
    if (keyBinding === "e") {
      return fieldName && fieldName.startsWith("B");
    } else if (keyBinding === "w") {
      return fieldName && (fieldName.startsWith("Di") || fieldName.startsWith("Df"));
    } else if (keyBinding === "q") {
      return fieldName && (fieldName.startsWith("Qi") || fieldName.startsWith("Qf"));
    }

    return false;
  };

  const handleFieldTraversal = (event) => {
    if (!globalRef?.current) {
      return;
    }

    if (event.key === "e" || event.key === "w" || event.key === "q") {
      // Jump to selected fields
      let nextIndex = globalRef?.current.findIndex(
        (ref) => ref?.current === inputRef.current
      );
      let nextInput = null;

      do {
        nextIndex = (nextIndex + 1) % globalRef.current.length;
        nextInput = globalRef.current[nextIndex].current;
      } while (!isNextField(nextInput?.input?.name, event.key));

      nextInput.focus();
      return;
    }

    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      // switching the same field between products
      const currentIndex = globalRef.current.findIndex(
        (ref) => ref?.current === inputRef.current
      );
      const currentInput = globalRef.current[currentIndex].current;

      let nextIndex = currentIndex;
      let nextInput = currentInput;

      do {
        nextIndex = nextIndex + (event.key === "ArrowLeft" ? -1 : 1);

        if (nextIndex < 0 || nextIndex >= globalRef.current.length) {
          // out of bound
          return;
        }

        nextInput = globalRef.current[nextIndex].current;
      } while (currentInput?.input?.name !== nextInput?.input?.name);

      if (currentInput?.input?.name === nextInput?.input?.name) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "e" ||
        event.key === "q" ||
        event.key === "w"
      ) {
        event.stopPropagation();
        event.preventDefault();

        handleFieldTraversal(event);
        return;
      }

      if (
        event.key !== "ArrowDown" &&
        event.key !== "ArrowUp" &&
        event.key !== "r" &&
        !event.ctrlKey &&
        event.key !== "s"
      ) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        const baseEvent = event as BaseSyntheticEvent;
        const useFineGrainedStep =
          event.getModifierState("Shift") &&
          (header.startsWith("D") || header.startsWith("Q"));

        let { increment } = getIncrement(
          baseEvent.target.value,
          useFineGrainedStep,
          header.startsWith("D") || header.startsWith("Q")
        );

        increment = event.key === "ArrowDown" ? -increment : increment;
        const synth = event as BaseSyntheticEvent;
        const value = synth.target.value;
        let num = parseFloat(value);
        if (isNaN(num) || !isFinite(num)) {
          return;
        }
        num = addDecimal(num, increment);
        if (header.includes("Di (tan)") && num >= 100.0) {
          num = 99.0;
        }

        setOwnedValueWrapper(num.toString());
        shortChangeDebounceRef.current(() => onChange && onChange(Number(ownedValue)));
      } else if (event.key === "Enter") {
        handleFocus(event);
      } else if (event.ctrlKey && event.key === "s") {
        handleSave();
      } else if (event.key === "r") {
        handleReload();
      }
    },
    [handleReload, handleReset, handleSave, onChange, step]
  );

  return (
    <Wrapper>
      <InputWrapper
        data-testid={"arp-input-field-" + header}
        name={header}
        disabled={disabled}
        step={step}
        min={min}
        ref={inputRef}
        type={type}
        onFocus={handleFocus}
        onBlur={handleFocusLost}
        color={isCalculated ? "#d5d5d5" : "#ebf8ef"}
        title={(ownedValue ?? "").toString()}
        value={ownedValue}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onChange={handleValueChanged}
      />
      {isCalculated && (
        <IconWrapper>
          <Icon path={mdiCalculator} size={1} />
        </IconWrapper>
      )}
      <UnitLabel>{unit}</UnitLabel>
    </Wrapper>
  );
}

const InputWrapper = styled(Input)`
  background-color: ${(props) => props.color};
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .ant-input {
    padding-right: 35px !important;
    width: 100%;
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
const IconWrapper = styled.div`
  font-size: 1rem;
  position: absolute;
  right: 29px;
  user-select: none;
  align-items: center;
  display: flex;
`;

const UnitLabel = styled.div`
  font-size: 1rem;
  position: absolute;
  right: 5px;
  user-select: none;
`;
