import { MutableRefObject } from "react";

import { DatePicker, InputRef } from "antd";
import { utc } from "moment";
import styled from "styled-components";
import {
  getDefaultCumUnits,
  getDefaultDeclineUnits,
  getDefaultRateUnits
} from "utils/arps";

import { UserArpsItem } from "models/UserArpsModel";

import { ProductTypeEnum } from "components/arps/models/ArpsSegment";

import { ArpInputField } from ".";

const ArpInput = (
  header: string,
  product: ProductTypeEnum,
  mode: "Product" | "Segment",
  obj,
  isCalculated: boolean,
  isForecastConstant: boolean,
  isRatio: boolean,
  isSync: boolean,
  handleReload: (item?: UserArpsItem) => void,
  handleReset: () => void,
  handleSave: (item?: UserArpsItem) => void,
  onChange: (val) => void,
  inputRef?: MutableRefObject<MutableRefObject<InputRef>[]>,
  onBlur?: () => void,
  isDisabled?: boolean
) => {
  function getHeaderUnits(header: string, product: ProductTypeEnum) {
    if (header.startsWith("Q") || header === "Max Rate") {
      return getDefaultRateUnits(product);
    } else if (header === "EUR" || header === "CTD") {
      return getDefaultCumUnits(product, true);
    } else if (header === "N2 Switch") {
      return "month";
    } else if (header === "Duration") {
      return "yr";
    } else if (header.startsWith("Di") || header.startsWith("Df")) {
      return getDefaultDeclineUnits(product);
    } else if (header.startsWith("t")) {
      return "month";
    }

    return "";
  }

  try {
    const key = header + "-" + product;
    let val: number = obj && header in obj ? obj[header] : null;
    if (isForecastConstant && obj.value) {
      val = obj.value;
    }
    if (isForecastConstant && header !== "Qi") {
      return <FillerDiv />;
    }
    if (header === "Start Date" || header === "End Date") {
      const date = val ? utc(obj[header]) : null;
      return (
        <DatePicker
          allowClear={false}
          value={date}
          disabled={
            isDisabled || mode === "Segment" || (isRatio && !isForecastConstant) || isSync
          }
          key={key}
          onChange={(evt) => onChange && onChange(evt?.toDate())}
        />
      );
    }

    return (
      <ArpInputField
        header={header}
        globalRef={inputRef}
        key={key}
        min={header.startsWith("Q") || header.startsWith("t") ? 0 : undefined} // Q = Flow rate, initial, final. t = times for ramp-up or constrained templates.
        type="number"
        isCalculated={isCalculated}
        unit={getHeaderUnits(header, product)}
        disabled={
          isDisabled ||
          mode === "Segment" ||
          header === "CTD" ||
          (isRatio && !isForecastConstant) ||
          isSync
        }
        step={header.startsWith("B") ? 0.1 : 1}
        value={Number(val)}
        handleReload={handleReload}
        handleReset={handleReset}
        handleSave={handleSave}
        onBlur={onBlur}
        onChange={(value) => {
          if (isNaN(value)) {
            value = 0;
          }
          onChange && onChange(value);
        }}
      />
    );
  } catch (err) {
    return <div>Error {err}</div>;
  }
};
const FillerDiv = styled.div`
  min-height: 27px;
`;

export default ArpInput;
