// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { mdiArrowLeftBold, mdiArrowRightBold, mdiRefresh, mdiStar } from "@mdi/js";
import { mdiFilter } from "@mdi/js/commonjs/mdi";
import { feature, featureCollection } from "@turf/helpers";
import { Button, Divider, Input, Slider, Switch, Typography } from "antd";
import colormap from "colormap";
import {
  AnyLayer,
  EventData,
  GeoJSONSource,
  LngLatBoundsLike,
  PointLike
} from "mapbox-gl";
import moment from "moment";
import styled from "styled-components";

import useBetaFeatures from "hooks/useBetaFeatures";

import { FACILITY_LAYER, WELL_LAYER, WELL_LAYER_POINT } from "../../constants";
import { EntityKind } from "../../models/entityKind";
import { filterWellsFromList, setSelectedFacilities } from "../../store/features";
import { filterWellsFromList as filterFacilitiesFromList } from "../../store/features/filter/facilityFilterSlice";
import { RootState } from "../../store/rootReducer";
import { DraggableLegendBase } from "../base/DraggableLegendBase";
import { ContextMenu } from "../chart";
import { IconButton } from "../pad-generator/ScenarioConfigurationTable";
import useGetContributingWells from "./hooks/useGetContributingWellsForFacilities";
import useGetFacilityContributingWellsNetwork from "./hooks/useGetFacilityContributingWellsNetwork";
import useGetFacilityFromWell from "./hooks/useGetFacilityFromWell";
import useGetFacilityNetworkFraction, {
  NetworkFraction
} from "./hooks/useGetFacilityNetworkFraction";
import { WellNetworkRequest, useGetWellNetworkMap } from "./hooks/useGetWellNetworkMap";
import { useMapContext } from "./hooks/useMapContext";
import { useMapDispatch } from "./hooks/useMapDispatch";
import useMutateWellsEnvelope from "./hooks/useMutateWellsEnvelope";
import { waitForStyleToLoad } from "./utils";
import { emptyFeatureCollection } from "./utils/emptyFeatureCollection";

//layer names
const HIGHLIGHTED_WELLS = "highlighted-wells";
const HIGHLIGHTED_VERTICAL_WELLS = "highlighted-vertical-wells";
const CLICKED_FACILITY = "clicked-facility";
const WELL_NETWORK_MAP = "well-network-map";
const CLICKED_WELL = "clicked-well";
const HIGHLIGHTED_FACILITY = "highlighted-facility";
const HIGHLIGHTED_WELL_FACILITIES = "highlighted-well-facilities";
const LEGEND_TITLE: string = "Current Month Well Contribution Percent";
const NETWORK_DEPTH_LEGEND_TITLE = "Network Depth";
const { Text, Title } = Typography;

//layer dimensions
const NETWORK_OPTIONS_DIMENSIONS = { width: 280, height: 220 };

/**
 * FacilityHighlighter component is responsible for highlighting facilities on a map.
 * It provides interactivity such as clicking and hovering over facilities.
 * @returns {JSX.Element} The rendered FacilityHighlighter component.
 */
export function FacilityHighlighter(): JSX.Element {
  const { mapbox, facilitiesInGroup } = useMapContext();
  const { hasFeature } = useBetaFeatures();
  const activeEntityKinds = useSelector(
    (state: RootState) => state.app.activeEntityKinds
  );
  const [clickedEntity, setClickedEntity] = useState<{
    facility: {
      facilityId: string;
      geometry: GeoJSON.FeatureCollection;
    };
    well: {
      wellId: string;
      geometry: GeoJSON.FeatureCollection;
    };
  }>({ facility: null, well: null });
  const getContributingWells = useGetContributingWells();
  const getFacilityContributingWellsNetwork = useGetFacilityContributingWellsNetwork();

  const {
    data: wellData,
    isLoading,
    isFetching
  } = useGetFacilityNetworkFraction(clickedEntity?.facility?.facilityId);

  const { data: facilityData, isFetching: isWellFetching } = useGetFacilityFromWell(
    clickedEntity?.well?.wellId
  );

  const [wellNetworkMapRequest, setWellNetworkMapRequest] = useState<WellNetworkRequest>({
    wellIds: [],
    date: null,
    onlyPrimaryPath: true
  });

  const { data: wellNetworkMap, isFetching: isNetworkMapFetching } =
    useGetWellNetworkMap(wellNetworkMapRequest);

  const { mutateAsync: getWellsEnvelope } = useMutateWellsEnvelope();
  const [showNetworkVisualOptions, setShowNetworkVisualOptions] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const mapDispatch = useMapDispatch();
  const dispatch = useDispatch();
  const [networkLineWidth, setNetworkLineWidth] = useState<[number, number]>([3, 12]);
  const [legend, setLegend] = useState<{
    legendItems: { title: string; color: string }[];
    visible: boolean;
    title: string;
  }>({
    legendItems: [],
    visible: false,
    title: LEGEND_TITLE
  });

  const [networkDepthLegend, setNetworkDepthLegend] = useState<{
    legendItems: { title: string; color: string }[];
    visible: boolean;
    title: string;
  }>({
    legendItems: [],
    visible: false,
    title: LEGEND_TITLE
  });
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);

  /******************FUNCTIONS********************************/
  const clearGeojsonSource = (layer: string) => {
    if (!mapbox) {
      return;
    }
    if (mapbox.getLayer(layer)) {
      (mapbox.getSource(layer) as GeoJSONSource)?.setData({ ...emptyFeatureCollection });
    }
  };

  const clearLegend = () => {
    setLegend({
      legendItems: [],
      title: LEGEND_TITLE,
      visible: false
    });
  };
  const clearNetworkDepthLegend = () => {
    setNetworkDepthLegend({
      legendItems: [],
      title: NETWORK_DEPTH_LEGEND_TITLE,
      visible: false
    });
  };
  const setGeojsonSource = (layer: string, geojson: GeoJSON.FeatureCollection) => {
    if (!mapbox) {
      return false;
    }
    if (mapbox.getLayer(layer) && mapbox.getSource(layer)) {
      const source = mapbox.getSource(layer) as GeoJSONSource;
      if (source) {
        source.setData(geojson);
        return true;
      }
    }
    return false;
  };

  const filterToContributingWells = () => {
    if (clickedEntity?.well) {
      dispatch(filterWellsFromList([clickedEntity?.well.wellId]));
      return;
    } else if (wellData && clickedEntity?.facility) {
      const uwids = wellData.map((item) => item.wellId);
      if (uwids?.length === 0) {
        toast.warn("No contributing wells found for selected facility.");
        return;
      }
      dispatch(filterWellsFromList(uwids));
    }
  };

  const filterToAllContributingWellsFromAllFacilities = async () => {
    const newFacilityList: string[] = [];
    for (const title of Object.keys(facilitiesInGroup)) {
      const group = facilitiesInGroup[title];

      for (const id of Object.keys(group.uwis)) {
        newFacilityList.push(id);
      }
    }

    try {
      const contributingWells = await getContributingWells.mutateAsync(newFacilityList);
      if (contributingWells) {
        const wellIds = contributingWells.map((well) => well.wellId);
        if (wellIds.length === 0) {
          toast.warn("No contributing wells found for facilities.");
          return;
        }
        dispatch(filterWellsFromList(wellIds));
      }
    } catch (error) {
      toast.error("Failed to fetch contributing wells.");
    }
  };

  const filterToNetworkForAllContributingWells = async () => {
    try {
      const facilityNetwork: NetworkFraction[] =
        await getFacilityContributingWellsNetwork.mutateAsync(
          clickedEntity?.facility?.facilityId
        );
      if (facilityNetwork) {
        const uniqueWellIds = [...new Set(facilityNetwork.map((well) => well.wellId))];
        const uniqueFacilityIds = [
          ...new Set(facilityNetwork.map((facility) => facility.destinationFacility))
        ];
        if (uniqueWellIds.length === 0) {
          toast.warn("No facility network found.");
          return;
        }
        dispatch(filterWellsFromList(uniqueWellIds));
        dispatch(filterFacilitiesFromList(uniqueFacilityIds));
      }
    } catch (error) {
      toast.error("Failed to fetch facility network.");
    }
  };

  const setContextMenu = (e: EventData) => {
    setShowContextMenu(false);

    setTimeout(() => {
      setContextMenuPosition({ x: e.point.x, y: e.point.y });
      setShowContextMenu(true);
    }, 100);
  };

  const filterToFacilities = () => {
    if (clickedEntity?.facility) {
      dispatch(filterFacilitiesFromList([clickedEntity.facility?.facilityId]));
    } else if (clickedEntity?.well) {
      const facilities = facilityData.map((item) => item.facilityId);
      if (facilities?.length === 0) {
        toast.warn("No sales facilities found for selected well.");
        return;
      }
      dispatch(filterFacilitiesFromList(facilities));
    }
  };

  const showWellNetworkMap = () => {
    if (!clickedEntity?.well) {
      return;
    }
    setWellNetworkMapRequest({
      ...wellNetworkMapRequest,
      wellIds: [clickedEntity.well.wellId]
    });
    setShowNetworkVisualOptions(true);
  };

  const getBoundingBox = (e: EventData) => {
    const buffer = 1;
    return [
      [e.point.x - buffer, e.point.y - buffer],
      [e.point.x + buffer, e.point.y + buffer]
    ] as [PointLike, PointLike];
  };

  const onClickFacility = (e: EventData) => {
    const bbox = getBoundingBox(e);
    const features = mapbox.queryRenderedFeatures(bbox, {
      layers: [FACILITY_LAYER]
    });

    const addClickedLayer = (geojson: GeoJSON.FeatureCollection) => {
      if (!mapbox) {
        return;
      }
      if (setGeojsonSource(CLICKED_FACILITY, geojson)) {
        return;
      }

      const layer = {
        id: CLICKED_FACILITY,
        type: "symbol",
        source: {
          type: "geojson",
          data: geojson
        },
        paint: {
          "icon-color": "black"
        },
        layout: {
          "icon-image": [
            "match",
            ["get", "facilityType"],
            "CompressorStation",
            "compressor-station",
            "GasPlant",
            "gas-plant",
            "Battery",
            "battery",
            "MeteringStation",
            "metering-station",
            "WastePlant",
            "waste-plant",
            "InjectionDisposalFacility",
            "injection-facility",
            "GatheringSystem",
            "gathering-system",
            /* default */ "default-facility"
          ],
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            6,
            0.3,
            10,
            1.1,
            12,
            1.6,
            22,
            2.1
          ]
        }
      } as AnyLayer;
      mapbox.addLayer(layer);
    };

    if (features.length > 0) {
      const clickedFeature = featureCollection([features[0]]);
      setClickedEntity({
        facility: {
          facilityId: features[0].properties?.Uwi,
          geometry: clickedFeature
        },
        well: null
      });
      const selectedFacilities = {};
      selectedFacilities[features[0].properties?.Uwi] = {
        Uwi: features[0].properties?.Uwi
      };

      clearGeojsonSource(CLICKED_FACILITY);
      clearGeojsonSource(CLICKED_WELL);
      if (e.type === "contextmenu") {
        setContextMenu(e);
      }
      addClickedLayer(clickedFeature);
    }
  };

  const onClickWell = useCallback(
    (e) => {
      if (!mapbox || !activeEntityKinds.includes(EntityKind.Facility)) {
        return;
      }
      const buffer = 2;
      const bbox = [
        [e.point.x - buffer, e.point.y - buffer],
        [e.point.x + buffer, e.point.y + buffer]
      ] as [PointLike, PointLike];
      const features = mapbox.queryRenderedFeatures(bbox, {
        layers: [WELL_LAYER]
      });

      const addClickedLayer = (geojson: GeoJSON.FeatureCollection) => {
        if (!mapbox) {
          return;
        }
        if (setGeojsonSource(CLICKED_WELL, geojson)) {
          return;
        }

        const layer = {
          id: CLICKED_WELL,
          type: "line",
          source: {
            type: "geojson",
            data: geojson
          },
          paint: {
            "line-color": "black",
            "line-width": 5
          }
        } as AnyLayer;
        mapbox.addLayer(layer);
      };

      if (features.length > 0) {
        const clickedFeature = featureCollection([features[0]]);
        setClickedEntity({
          well: {
            wellId: features[0].properties?.Uwi,
            geometry: clickedFeature
          },
          facility: null
        });
        clearGeojsonSource(CLICKED_FACILITY);
        addClickedLayer(clickedFeature);
        if (e.type === "contextmenu") {
          setContextMenu(e);
        }
      }
    },
    [mapbox, activeEntityKinds, facilitiesInGroup]
  );
  const clearNetworkMap = () => {
    clearGeojsonSource(WELL_NETWORK_MAP);
    clearNetworkDepthLegend();
    setShowNetworkVisualOptions(false);
  };

  const clearEverything = () => {
    clearLegend();
    clearGeojsonSource(HIGHLIGHTED_WELL_FACILITIES);
    clearGeojsonSource(HIGHLIGHTED_FACILITY);
    clearGeojsonSource(HIGHLIGHTED_WELLS);
    clearGeojsonSource(HIGHLIGHTED_VERTICAL_WELLS);
    clearGeojsonSource(CLICKED_FACILITY);
    clearGeojsonSource(CLICKED_WELL);
    clearNetworkMap();
    setClickedEntity({ facility: null, well: null });
  };

  const highlightContributionWells = useCallback(() => {
    //highlight wells matching data
    if (!mapbox || !wellData) {
      return;
    }

    function clearHighlightedWells() {
      clearGeojsonSource(HIGHLIGHTED_WELLS);
      clearGeojsonSource(HIGHLIGHTED_VERTICAL_WELLS);
    }

    function setHighlightedHzWells(
      collection: GeoJSON.FeatureCollection,
      stops: (string | number)[][]
    ) {
      if (!setGeojsonSource(HIGHLIGHTED_WELLS, collection)) {
        const layer = {
          id: HIGHLIGHTED_WELLS,
          type: "line",
          source: {
            type: "geojson",
            data: collection
          },
          paint: {
            "line-color": {
              property: "value",
              stops
            },
            "line-width": 5
          }
        } as AnyLayer;
        mapbox.addLayer(layer, CLICKED_FACILITY);
      } else {
        mapbox.setPaintProperty(HIGHLIGHTED_WELLS, "line-color", {
          property: "value",
          stops
        });
      }
    }

    function setHighlightedVerticalWells(
      collection: GeoJSON.FeatureCollection,
      stops: (string | number)[][]
    ) {
      if (!setGeojsonSource(HIGHLIGHTED_VERTICAL_WELLS, collection)) {
        const layer = {
          id: HIGHLIGHTED_VERTICAL_WELLS,
          type: "circle",
          source: {
            type: "geojson",
            data: collection
          },
          paint: {
            "circle-color": {
              property: "value",
              stops
            },
            "circle-radius": 5
          }
        } as AnyLayer;
        mapbox.addLayer(layer, CLICKED_FACILITY);
      } else {
        mapbox.setPaintProperty(HIGHLIGHTED_VERTICAL_WELLS, "circle-color", {
          property: "value",
          stops
        });
      }
    }

    async function showWellsContributingToFacility() {
      if (!mapbox.isStyleLoaded()) {
        await waitForStyleToLoad(mapbox, 1000);
      }
      clearHighlightedWells();

      if (wellData.length === 0) {
        clearLegend();
        toast.warn("No contributing wells found for selected facility.");
        return;
      }

      let envelope: {
        minX: number;
        minY: number;
        maxX: number;
        maxY: number;
      } = null;
      try {
        envelope = await getWellsEnvelope(clickedEntity?.facility?.facilityId);
        if (
          !envelope ||
          envelope.minY === 0 ||
          envelope.minX === 0 ||
          envelope.maxX === 0 ||
          envelope.maxY === 0 ||
          envelope.minX === envelope.maxX ||
          envelope.minY === envelope.maxY
        ) {
          toast.warn("No contributing wells found for selected facility.");
          return;
        }
      } catch {
        toast.error("Error fetching contributing wells");
        return;
      }
      const bounds = [
        [envelope.minX, envelope.minY],
        [envelope.maxX, envelope.maxY]
      ] as LngLatBoundsLike;

      await new Promise<void>((resolve) => {
        mapbox.once("moveend", () => {
          mapbox.once("idle", () => resolve());
        });
        mapbox.fitBounds(bounds, {
          padding: 50,
          duration: 3
        });
      });
      //wait some time for the map to load data from cache/server to tiles
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1500);
      });
      const min = 0;
      const max = 100;
      const ids = wellData.reduce((obj, item) => {
        obj[item.wellId] = item.gasPct;
        return obj;
      }, {});

      const steps = (max - min) / 5.0;

      const colors = colormap({
        colormap: "hot",
        nshades: 7,
        format: "hex",
        alpha: 1
      })
        .reverse()
        .slice(1);
      const legendItems = [];

      const stops = Array.from({ length: 5 }, (v, i) => {
        legendItems.push({
          title: `${Math.round(min + i * steps).toLocaleString()} - ${Math.round(
            min + (i + 1) * steps
          ).toLocaleString()}`,
          color: colors[i]
        });
        return [min + i * steps, colors[i]];
      });

      setLegend({
        legendItems,
        title: LEGEND_TITLE,
        visible: true
      });

      const foundHzWells = mapbox.querySourceFeatures(WELL_LAYER, {
        sourceLayer: "eva-wells",
        filter: ["has", ["get", "Uwi"], ["literal", ids]]
      });

      const foundVerticalWells = mapbox.querySourceFeatures(WELL_LAYER_POINT, {
        sourceLayer: "eva-wells",
        filter: ["has", ["get", "Uwi"], ["literal", ids]]
      });

      const hzFeatures = foundHzWells.map((feat) => {
        return feature(feat.geometry, {
          ...feat.properties,
          value: ids[feat.id]
        });
      });
      const vFeatures = foundVerticalWells.map((feat) => {
        return feature(feat.geometry, {
          ...feat.properties,
          value: ids[feat.id]
        });
      });
      setHighlightedHzWells(featureCollection(hzFeatures), stops);
      setHighlightedVerticalWells(featureCollection(vFeatures), stops);
    }

    showWellsContributingToFacility();
  }, [wellData, mapbox]);

  /****************EFFECTS**********************************/

  useResizeObserver(containerRef, (entry) => {
    setContainerHeight(entry.contentRect.height);
    setContainerWidth(entry.contentRect.width);
  });

  useHotkeys(
    "esc",
    () => {
      if (!mapbox) {
        return;
      }
      clearEverything();
      setClickedEntity(null);
    },
    {
      keyup: true
    },
    [mapbox]
  );

  useEffect(() => {
    if (!mapbox) {
      return;
    }
    if (!activeEntityKinds.includes(EntityKind.Facility)) {
      clearEverything();
    }
    const clearFacilityHighlight = () => {
      clearGeojsonSource(HIGHLIGHTED_FACILITY);
    };
    const highlightFacility = (geojson: GeoJSON.FeatureCollection) => {
      if (!mapbox) {
        return;
      }

      if (geojson.features.length === 0) {
        return;
      }
      if (!setGeojsonSource(HIGHLIGHTED_FACILITY, geojson)) {
        const layer = {
          id: HIGHLIGHTED_FACILITY,
          type: "symbol",
          source: {
            type: "geojson",
            data: geojson
          },
          paint: {
            "icon-color": "black"
          },
          layout: {
            "icon-image": [
              "match",
              ["get", "facilityType"],
              "CompressorStation",
              "compressor-station",
              "GasPlant",
              "gas-plant",
              "Battery",
              "battery",
              "MeteringStation",
              "metering-station",
              "WastePlant",
              "waste-plant",
              "InjectionDisposalFacility",
              "injection-facility",
              "GatheringSystem",
              "gathering-system",
              /* default */ "default-facility"
            ],
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              6,
              0.3,
              10,
              1.1,
              12,
              1.6,
              22,
              2.1
            ]
          }
        } as AnyLayer;
        mapbox.addLayer(layer);
      } else {
        mapbox.setPaintProperty(HIGHLIGHTED_FACILITY, "icon-color", "black");
      }
    };

    const onMouseMove = (e: EventData) => {
      const buffer = 1;
      const bbox = [
        [e.point.x - buffer, e.point.y - buffer],
        [e.point.x + buffer, e.point.y + buffer]
      ] as [PointLike, PointLike];
      const features = mapbox.queryRenderedFeatures(bbox, {
        layers: [FACILITY_LAYER]
      });
      if (features.length === 0) {
        return;
      }
      const geojson = { ...emptyFeatureCollection, features };
      highlightFacility(geojson);
    };

    const hideContextMenu = () => {
      setShowContextMenu(false);
    };

    const setSelectedFacility = (e) => {
      const bbox = getBoundingBox(e);
      const features = mapbox.queryRenderedFeatures(bbox, {
        layers: [FACILITY_LAYER]
      });
      if (!features.length) {
        return;
      }

      const selectedFacilities = {};
      selectedFacilities[features[0].properties?.Uwi] = {
        Uwi: features[0].properties?.Uwi
      };

      dispatch(setSelectedFacilities(selectedFacilities));
    };

    mapbox.on("click", WELL_LAYER, onClickWell);
    mapbox.on("contextmenu", WELL_LAYER, onClickWell);
    mapbox.on("mouseenter", FACILITY_LAYER, onMouseMove);
    mapbox.on("click", FACILITY_LAYER, setSelectedFacility);
    mapbox.on("contextmenu", FACILITY_LAYER, onClickFacility);
    mapbox.on("mouseleave", FACILITY_LAYER, clearFacilityHighlight);
    mapbox.on("click", hideContextMenu);
    return () => {
      mapbox.off("click", WELL_LAYER, onClickWell);
      mapbox.off("contextmenu", WELL_LAYER, onClickWell);
      mapbox.off("mousemove", FACILITY_LAYER, onMouseMove);
      mapbox.off("click", FACILITY_LAYER, onClickFacility);
      mapbox.off("contextmenu", FACILITY_LAYER, onClickFacility);
      mapbox.off("mouseleave", FACILITY_LAYER, clearFacilityHighlight);
      mapbox.off("click", hideContextMenu);
    };
  }, [mapbox, onClickWell]);

  useEffect(() => {
    if (!mapbox || !wellNetworkMap) {
      return;
    }
    const keys = Object.keys(wellNetworkMap.wellFeatureCollections);
    if (keys.length === 0) {
      clearGeojsonSource(WELL_NETWORK_MAP);
      return;
    }
    const maxDepth = wellNetworkMap.maxDepth;
    const colors = colormap({
      colormap: "autumn",
      nshades: Math.max(3, maxDepth + 1),
      format: "hex",
      alpha: 1
    });
    const geojson = wellNetworkMap.wellFeatureCollections[keys[0]];
    const depthAndColors = colors
      .map((color: string, index: number) => {
        return [index, color];
      })
      .flat();
    const lineColorStyle = ["match", ["get", "depth"], ...depthAndColors, "#000000"];
    const lineWidthStyle = [
      "interpolate",
      ["linear"],
      ["get", "fraction"],
      0.01,
      networkLineWidth[0],
      1.0,
      networkLineWidth[1]
    ];
    setNetworkDepthLegend({
      title: NETWORK_DEPTH_LEGEND_TITLE,
      legendItems: colors.map((color: string, index: number) => {
        return {
          color,
          title: index.toString()
        };
      }),
      visible: true
    });

    if (!setGeojsonSource(WELL_NETWORK_MAP, geojson)) {
      const layer = {
        id: WELL_NETWORK_MAP,
        type: "line",
        source: {
          type: "geojson",
          data: geojson
        },
        paint: {
          "line-color": lineColorStyle,
          "line-width": lineWidthStyle
        }
      } as AnyLayer;
      mapbox.addLayer(layer);
    } else {
      mapbox.setPaintProperty(WELL_NETWORK_MAP, "line-width", lineWidthStyle);
      mapbox.setPaintProperty(WELL_NETWORK_MAP, "line-color", lineColorStyle);
    }
  }, [wellNetworkMap, networkLineWidth, mapbox]);

  useEffect(() => {
    mapDispatch({
      type: "update",
      payload: {
        isLoading: isLoading || isFetching || isWellFetching || isNetworkMapFetching
      }
    });
  }, [isLoading, isNetworkMapFetching, isFetching, isWellFetching]);

  return (
    <Root
      ref={containerRef}
      onContextMenu={(e) => e.preventDefault()}
      data-testid={"facility-highlighter"}>
      <DraggableLegendBase
        visible={legend.visible}
        closeable
        onClose={() => {
          clearLegend();
          clearGeojsonSource(HIGHLIGHTED_FACILITY);
          clearGeojsonSource(HIGHLIGHTED_WELLS);
          clearGeojsonSource(HIGHLIGHTED_VERTICAL_WELLS);
          setClickedEntity(null);
        }}
        parentDimensions={
          containerWidth && containerHeight
            ? { width: containerWidth, height: containerHeight }
            : {
                width: containerRef.current?.clientWidth ?? 100,
                height: containerRef.current?.clientHeight ?? 100
              }
        }
        legendTitle={<Title level={5}>{legend.title}</Title>}>
        {legend.legendItems.map((item) => {
          return (
            <div key={item.title} style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: item.color,
                  marginRight: "5px"
                }}
              />
              <Text style={{ fontSize: "1.2em" }}>{item.title}</Text>
            </div>
          );
        })}
      </DraggableLegendBase>
      {activeEntityKinds.includes(EntityKind.Facility) &&
        !hasFeature("Facility Volumes") && (
          <ContextMenu
            className={"map-context-menu"}
            visible={showContextMenu}
            allow={showContextMenu}
            position={contextMenuPosition}
            data-testid={"facility-context-menu"}
            content={
              <ContextMenuContent>
                {clickedEntity?.well ? (
                  <>
                    <Button
                      type={"text"}
                      icon={<Icon path={mdiFilter} size={1.0} />}
                      onClick={() => {
                        filterToFacilities();
                        setShowContextMenu(false);
                      }}
                      onContextMenu={(e) => e.preventDefault()}>
                      Filter To Sales Facilities
                    </Button>
                    <Button
                      type={"text"}
                      icon={<Icon path={mdiFilter} size={1.0} />}
                      onClick={() => {
                        showWellNetworkMap();
                        setShowContextMenu(false);
                      }}
                      onContextMenu={(e) => e.preventDefault()}>
                      Show Well To Facility Network
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type={"text"}
                      icon={<Icon path={mdiStar} size={1.0} />}
                      onClick={() => {
                        highlightContributionWells();
                        setShowContextMenu(false);
                      }}
                      onContextMenu={(e) => e.preventDefault()}>
                      Highlight Contributing Wells
                    </Button>
                    <Divider />
                    <Button
                      type={"text"}
                      icon={<Icon path={mdiFilter} size={1.0} />}
                      onClick={() => {
                        filterToContributingWells();
                        filterToFacilities();
                        setShowContextMenu(false);
                      }}
                      onContextMenu={(e) => e.preventDefault()}>
                      Filter To Facility And Contributing Wells
                    </Button>
                    <Button
                      type={"text"}
                      icon={<Icon path={mdiFilter} size={1.0} />}
                      onClick={async () => {
                        await filterToAllContributingWellsFromAllFacilities();
                        setShowContextMenu(false);
                      }}
                      onContextMenu={(e) => e.preventDefault()}>
                      Filter To Contributing Wells From All Facilities
                    </Button>
                    <Button
                      type={"text"}
                      icon={<Icon path={mdiFilter} size={1.0} />}
                      onClick={async () => {
                        await filterToNetworkForAllContributingWells();
                        setShowContextMenu(false);
                      }}
                      onContextMenu={(e) => e.preventDefault()}>
                      Filter To Network
                    </Button>
                  </>
                )}
              </ContextMenuContent>
            }
          />
        )}
      {showNetworkVisualOptions && (
        <DraggableLegendBase
          visible={true}
          closeable
          initialWidth={NETWORK_OPTIONS_DIMENSIONS.width}
          initialHeight={NETWORK_OPTIONS_DIMENSIONS.height}
          onClose={() => {
            clearNetworkDepthLegend();
            clearNetworkMap();
            setClickedEntity(null);
          }}
          parentDimensions={
            containerWidth && containerHeight
              ? { width: containerWidth, height: containerHeight }
              : {
                  width: containerRef.current?.clientWidth ?? 100,
                  height: containerRef.current?.clientHeight ?? 100
                }
          }
          legendTitle={<Title level={5}>Network Options</Title>}>
          <NetworkMapOptions
            onClose={clearNetworkMap}
            showOnlyPrimaryPath={wellNetworkMapRequest.onlyPrimaryPath}
            width={networkLineWidth}
            onChange={(value) => {
              setWellNetworkMapRequest({
                ...wellNetworkMapRequest,
                onlyPrimaryPath: value.showOnlyPrimaryPath,
                date: value.date,
                hideConnectionsLessThanPercent: value.hideConnectionsLessThanPercent
              });
            }}
            date={wellNetworkMapRequest.date ?? wellNetworkMap?.date}
            startDate={wellNetworkMap?.startDate}
            endDate={wellNetworkMap?.endDate}
            hideConnectionsLessThanPercent={
              wellNetworkMapRequest.hideConnectionsLessThanPercent
            }
            onWidthChange={(value) => {
              setNetworkLineWidth(value);
            }}
          />
        </DraggableLegendBase>
      )}
      {showNetworkVisualOptions && (
        <DraggableLegendBase
          visible={networkDepthLegend.visible}
          closeable
          yOffset={NETWORK_OPTIONS_DIMENSIONS.height + 5}
          onClose={() => {
            clearNetworkDepthLegend();
            clearNetworkMap();
            setClickedEntity(null);
          }}
          parentDimensions={
            containerWidth && containerHeight
              ? { width: containerWidth, height: containerHeight }
              : {
                  width: containerRef.current?.clientWidth ?? 100,
                  height: containerRef.current?.clientHeight ?? 100
                }
          }
          legendTitle={<Title level={5}>{networkDepthLegend.title}</Title>}>
          {networkDepthLegend.legendItems.map((item) => {
            return (
              <div key={item.title} style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: item.color,
                    marginRight: "5px"
                  }}
                />
                <Text style={{ fontSize: "1.2em" }}>{item.title}</Text>
              </div>
            );
          })}
        </DraggableLegendBase>
      )}
    </Root>
  );
}

interface NetworkMapSettings {
  showOnlyPrimaryPath?: boolean;
  minFraction?: number;
  date?: string;
  startDate?: Date;
  endDate?: Date;
  width: [number, number];
  onChange?: (settings: NetworkMapSettings) => void;
  onWidthChange?: (width: [number, number]) => void;
  onClose: () => void;
  hideConnectionsLessThanPercent?: number;
}

function NetworkMapOptions(options: NetworkMapSettings): JSX.Element {
  const canGoToPreviousMonth = () =>
    options.startDate &&
    options.date &&
    moment(options.date, "YYYY-MM-DD") > moment(options.startDate);

  const canGoToNextMonth = () =>
    options.endDate &&
    options.date &&
    moment(options.date, "YYYY-MM-DD") < moment(options.endDate);

  const goToPreviousMonth = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();
    const currentMonth = options.date;
    if (!currentMonth || !canGoToPreviousMonth()) {
      return;
    }
    const date = moment(currentMonth, "YYYY-MM-DD");
    date.subtract(1, "months");
    options.onChange({ ...options, date: date.format("YYYY-MM-DD") });
  };

  const goToNextMonth = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();
    const currentMonth = options.date;
    if (!currentMonth || !canGoToNextMonth()) {
      return;
    }
    const date = moment(currentMonth, "YYYY-MM-DD");
    date.add(1, "months");
    options.onChange({ ...options, date: date.format("YYYY-MM-DD") });
  };

  useHotkeys("left", goToPreviousMonth, { keyup: true }, [
    options.date,
    options.startDate
  ]);
  useHotkeys("right", goToNextMonth, { keyup: true }, [options]);

  return (
    <NetworkMapOptionsRoot>
      <OptionItem>
        <label htmlFor={"primary-path-opt"}>Primary Path</label>
        <Switch
          checked={options.showOnlyPrimaryPath}
          id={"primary-path-opt"}
          onChange={(checked) =>
            options.onChange({ ...options, showOnlyPrimaryPath: checked })
          }
        />
      </OptionItem>

      <OptionItem>
        <label>
          Month
          <IconButton
            title={"Reset"}
            type={"text"}
            shape={"circle"}
            icon={<Icon path={mdiRefresh} size={1.0} />}
            onClick={() => options.onChange({ ...options, date: null })}
          />
        </label>
        <MonthSelection style={{ minWidth: "100px" }}>
          <IconButton
            title={"Previous Month"}
            icon={<Icon path={mdiArrowLeftBold} size={1.0} />}
            type={"text"}
            disabled={!canGoToPreviousMonth()}
            shape={"circle"}
            onClick={goToPreviousMonth}
          />
          <div className={"current-month-showing"}>{options.date ?? ""}</div>
          <IconButton
            title={"Next Month"}
            icon={<Icon path={mdiArrowRightBold} size={1.0} />}
            type={"text"}
            disabled={!canGoToNextMonth()}
            shape={"circle"}
            onClick={goToNextMonth}
          />
        </MonthSelection>
      </OptionItem>
      <OptionItem>
        <label style={{ minWidth: "150px" }}>Hide Connections LT</label>
        <Input
          type={"number"}
          placeholder={"< %"}
          value={options.hideConnectionsLessThanPercent}
          onChange={(e) =>
            options.onChange({
              ...options,
              hideConnectionsLessThanPercent: parseInt(e.target.value)
            })
          }
          min={0}
          max={100}
        />
        %
      </OptionItem>
      <OptionItem>
        <label>Width</label>
        <Slider
          min={1}
          max={20}
          value={options.width}
          range={true}
          onChange={(value) => options.onWidthChange(value)}
        />
      </OptionItem>
    </NetworkMapOptionsRoot>
  );
}

const OptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-slider {
    min-width: 100px;
  }
`;
const MonthSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .current-month-showing {
    text-align: center;
    width: 80px;
  }
`;

const NetworkMapOptionsRoot = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  pointer-events: auto;
  z-index: 10;

  .ant-card-body {
    padding: 0;
    width: 100%;
  }
`;
const ContextMenuContent = styled.div`
  .ant-divider {
    margin: 5px 0;
  }

  .ant-btn {
    display: flex;
    align-items: center;
    min-width: 150px;
    padding: 15px 5px;
    margin: 5px;
    width: 100%;

    svg {
      min-width: 20px;
      margin: 5px;
    }
  }
`;

const Root = styled.div`
  position: absolute;
  display: block;
  user-select: none;
  z-index: 1;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
