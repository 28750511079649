import { AVG_DAYS_IN_MONTH } from "../../arps/utils/dates";

/**
 * Adds a specified number of months to a given date, accounting for fractional months.
 * This function returns a new date object with the added months in UTC, maintaining the original timestamp.
 * The average number of days is to be consistent with the rest of the app since some calculations happen in days.
 */
export default function addMonthsToDate(date_utc: Date, monthsToAdd: number): Date {
  const newDate = new Date(
    Date.UTC(
      date_utc.getUTCFullYear(),
      date_utc.getUTCMonth(),
      date_utc.getUTCDate(),
      date_utc.getUTCHours(),
      date_utc.getUTCMinutes(),
      date_utc.getUTCSeconds(),
      date_utc.getUTCMilliseconds()
    )
  );

  // For granularity and precision, use milliseconds.
  const currentTimeInMillis = newDate.getTime();

  const isSubtracting = monthsToAdd < 0;
  const absoluteDaysToAdd = Math.abs(monthsToAdd * AVG_DAYS_IN_MONTH);
  const roundedDaysToAdd = Math.ceil(absoluteDaysToAdd);
  const daysToAdd = isSubtracting ? -roundedDaysToAdd : roundedDaysToAdd;
  newDate.setTime(currentTimeInMillis + daysToAdd * 24 * 60 * 60 * 1000);

  return newDate;
}
