import { useQuery } from "react-query";

import axios from "axios";
import { DATA_SERVICE_ADDRESS } from "constants/app.constants";

import { CumulativeData } from "../Forecasting";

export const FetchCumulativeDataQuery = "fetchCumulativeData";

const API_URL = `${DATA_SERVICE_ADDRESS}/api/v1/data/from-well-list`;

const fetchCumulativeData = async (uwi: string): Promise<CumulativeData | null> => {
  if (uwi.length === 0) {
    return null;
  }
  const response = await axios.post(API_URL, {
    uwis: [uwi],
    fields: [
      "Performance.Oil.CumToDate",
      "Performance.Gas.CumToDate",
      "Performance.Water.CumToDate",
      "Dates.LastProdDate",
      "McD_Header.McDPrimaryProduct"
    ]
  });

  const rawData = response.data[uwi];
  const oilAndCondensate = parseFloat(rawData[0]) || 0;
  return {
    OIL: oilAndCondensate,
    GAS: parseFloat(rawData[1]) || 0,
    WATER: parseFloat(rawData[2]) || 0,
    CONDENSATE: oilAndCondensate,
    LAST_PROD_DATE: rawData[3],
    PRIMARY_PRODUCT: rawData[4] === "WTR" ? "WATER" : rawData[4]
  } as CumulativeData;
};

/**
 * Custom hook to fetch cumulative production data for oil, gas, and water using React Query.
 *
 * This hook fetches the historical performance data for the given well, which is then used to calculate the true EUR.
 *
 * @param {string} uwi - The unique well identifier for which to fetch cumulative data.
 * @returns {QueryObserverResult<CumulativeData | null>} - The result of the query, which includes the cumulative well data or null.
 */
const useFetchCumulativeData = (uwi: string) => {
  return useQuery<CumulativeData | null>(
    [FetchCumulativeDataQuery, uwi],
    () => fetchCumulativeData(uwi),
    {
      enabled: uwi.length > 0,
      refetchOnWindowFocus: false
    }
  );
};

export default useFetchCumulativeData;
