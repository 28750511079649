import { SegmentDto } from "arps_wasm";

import { ArpSegment } from "models/UserArpsModel";

import { CumulativeData } from "components/forecasting/Forecasting";

import { ProductTypeEnum } from "../models/ArpsSegment";
import { SegmentTemplate } from "../models/SegmentTemplate";
import { arpsWasm } from "./UpdateSegment";
import {
  AVG_DAYS_IN_MONTH,
  AVG_DAYS_IN_YEAR,
  getDurationInDays,
  getDurationInMonths,
  getStartOfNextMonth
} from "./dates";
import { getDeclineTitleWithType } from "./declineHelpers";

const EPSILON = 1e-10;

export function ArpsSegmentToSegmentDto(
  arpsWasm: typeof import("wasm/arps"),
  segment: ArpSegment[]
): SegmentDto[] {
  if (!segment) {
    return [];
  }
  return segment.map((s) => arpsWasm.arpSegmentToSegmentDto(s));
}

export function getDefaultSegment(
  arpsWasm: typeof import("wasm/arps")
): (product: ProductTypeEnum) => SegmentDto[] {
  return (product) => {
    const date = getStartOfNextMonth();
    return arpsWasm.getArpsSegmentsFromBasicArps(
      product,
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      100.0,
      30.0,
      0.0,
      12,
      null,
      5.0,
      null,
      null
    );
  };
}

export const declineTypes = {
  Secant: "sec",
  Tangent: "tan",
  Nominal: "nom"
};

export function convertDecline(di, b, type) {
  let result: number;
  if (type === "Tangent") {
    result = arpsWasm.nominalToTangent(di) * 100;
  } else if (type === "Secant") {
    result = arpsWasm.nominalToSecant(di, b) * 100;
  } else {
    result = di * 100;
  }
  // Rounding to six decimal places to ensure values like .999999 are rounded up.
  return Math.round(result * 1000000) / 1000000;
}

export function convertDeclineToNominal(di, b, type) {
  if (type === "Tangent") {
    return arpsWasm.tangentToNominal(di / 100.0);
  } else if (type === "Secant") {
    return arpsWasm.secantToNominal(di / 100.0, b);
  } else {
    return di / 100;
  }
}

export function getSpacingBetweenValues(
  start: number,
  end: number,
  numberOfItems: number
) {
  return (start > end ? start - end : end - start) / numberOfItems;
}

export function getRampUpSegmentCumVolume(arpsSegment: ArpSegment) {
  const monthDifference = getDurationInMonths(arpsSegment.startDate, arpsSegment.endDate);

  const dayDifference = getDurationInDays(arpsSegment.startDate, arpsSegment.endDate);

  const daySpacing = getSpacingBetweenValues(
    arpsSegment.qi,
    arpsSegment.qf,
    dayDifference
  );

  let start = new Date(arpsSegment.startDate.split("Z")[0]);
  const endDate = new Date(arpsSegment.endDate.split("Z")[0]);
  let idx = 0;
  let currentCum = 0;
  let currentRate = arpsSegment.qi;

  while (idx < monthDifference) {
    const nextDay = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    // stop right before end date
    const days = nextDay < endDate ? nextDay.getDate() : endDate.getDate() - 1;

    for (let i = 1; i <= days; i++) {
      currentRate += daySpacing;
      currentCum += currentRate;
    }

    start.setMonth(start.getMonth() + 1);
    if (start > endDate) {
      start = endDate;
    }

    idx++;
  }
  return currentCum;
}

export function getTypewellTemplateFields(
  productSegments: ArpSegment[],
  declineType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultTypeWell?: any
) {
  const segmentLength = productSegments.length;
  let eur = 0;

  const hasRampUpSegment =
    segmentLength > 2 &&
    productSegments[0].qi <= productSegments[0].qf &&
    productSegments[0].di < 0;

  const hasConstrainedSegment = segmentLength === 4 && !hasRampUpSegment;

  const hasInitialSegment = hasRampUpSegment || hasConstrainedSegment;

  productSegments.forEach((s, i) => {
    if (hasRampUpSegment && s.qi <= s.qf && s.di < 0 && i == 0) {
      eur += getRampUpSegmentCumVolume(s) * 0.001;
    } else {
      const startDate_unixTimestamp_seconds = Math.round(
        new Date(s.startDate).getTime() / 1000
      );
      const endDate_unixTimestamp_seconds = Math.round(
        new Date(s.endDate).getTime() / 1000
      );
      const segVolume =
        arpsWasm.getSegmentVolume(
          s.qi,
          s.di,
          s.b,
          s.qf,
          BigInt(startDate_unixTimestamp_seconds),
          BigInt(endDate_unixTimestamp_seconds)
        ) * 0.001;
      eur += segVolume;
    }
  });

  const seg1 = productSegments[0];
  const seg2 = hasInitialSegment ? productSegments[1] : null;
  const lastSegment = productSegments[segmentLength - 1];
  const secondToLastSegment =
    segmentLength > 2 ? productSegments[segmentLength - 2] : null;

  // tRamp only available for 2 ramp-up and constrains templates
  const tRamp = hasInitialSegment
    ? Math.round(
        (getDurationInDays(seg1.startDate, seg1.endDate) / AVG_DAYS_IN_MONTH) * 10
      ) / 10 // show precision to one decimal place
    : undefined;

  const is3SegmentsTemplate =
    (segmentLength === 3 && !hasRampUpSegment) || segmentLength === 4;

  const Bhyp = is3SegmentsTemplate
    ? secondToLastSegment.b
    : segmentLength > 1
    ? hasInitialSegment
      ? seg2.b
      : seg1.b
    : seg1.b;

  const Btrans = is3SegmentsTemplate
    ? hasInitialSegment
      ? seg2.b
      : seg1.b
    : defaultTypeWell?.values.Btrans; // Previous segment didn't have Btrans, use the default

  const Bf = segmentLength > 1 ? lastSegment?.b ?? seg2.b : defaultTypeWell?.values.Bf; // Previous segment didn't have Bf, use the default

  // tTrans only available for 3 segment templates
  const tTrans = is3SegmentsTemplate
    ? hasInitialSegment
      ? Math.round(getDurationInDays(seg2.startDate, seg2.endDate) / AVG_DAYS_IN_MONTH)
      : Math.round(getDurationInDays(seg1.startDate, seg1.endDate) / AVG_DAYS_IN_MONTH)
    : undefined;

  if (!declineType) {
    return {
      "Start Date": seg1.startDate,
      Q0: hasInitialSegment ? seg1.qi : undefined,
      Bcons: hasConstrainedSegment ? seg1.b : undefined,
      tCons: hasConstrainedSegment ? tRamp : undefined,
      Qi: hasInitialSegment ? seg2.qi : seg1.qi,
      Di: hasInitialSegment ? seg2.di : seg1.di,
      B: hasInitialSegment ? seg2.b : seg1.b,
      Btrans: Btrans,
      Bhyp: Bhyp,
      Bf: Bf,
      tRamp: hasInitialSegment ? tRamp : undefined,
      tTrans: tTrans,
      Df: secondToLastSegment ? secondToLastSegment.df : seg1.df,
      Qf: lastSegment.qf,
      EUR: eur
    };
  }

  return {
    "Start Date": seg1.startDate,
    Q0: seg1.qi,
    Bcons: hasConstrainedSegment ? seg1.b : undefined,
    tCons: hasConstrainedSegment ? tRamp : undefined,
    Qi: hasInitialSegment ? seg2.qi : seg1.qi,
    [getDeclineTitleWithType("Di", declineType)]: roundTo6Decimal(
      convertDecline(
        hasInitialSegment ? seg2.di : seg1.di,
        hasInitialSegment ? seg2.b : seg1.b,
        declineType
      )
    ),
    B: segmentLength === 1 ? seg1.b : undefined, // B only available for 1 segment templates
    Btrans: is3SegmentsTemplate ? Btrans : undefined, // Btrans only available for 3 segment templates
    Bhyp: segmentLength > 1 ? Bhyp : undefined, // Bhyp only available for 2 segment templates and above
    Bf: segmentLength > 1 ? Bf : undefined, // Bf only available for 2 segment templates and above
    tRamp: hasInitialSegment ? tRamp : undefined,
    tTrans: tTrans,
    [getDeclineTitleWithType("Df", declineType)]: roundTo6Decimal(
      secondToLastSegment
        ? convertDecline(secondToLastSegment.df, secondToLastSegment.b, declineType)
        : convertDecline(seg1.df, seg1.b, declineType)
    ),
    Qf: lastSegment.qf.toFixed(2),
    EUR: eur.toFixed(2)
  };
}

/// The `get_di_at_day` function calculates the value of di based on the given parameters.
/// It first converts the day to a fractional year value (t) by dividing it by the number of days in a year (DAYS_IN_YEAR).
/// Then, it performs different calculations depending on the values of b:
///   - If the absolute value of b is less than a small threshold (EPSILON),
///     it calculates di as -(qf / qi).ln() / t.
///   - If the absolute difference between b and 1.0 is less than EPSILON,
///     it calculates di as (qi - qf) / q * t.
///   - Otherwise, it calculates di as (qi.pow(b) / qf.pow(b)).pow(b - 1.0) / (b * t).
/// Note: EPSILON is a small threshold used for floating-point comparisons.
///
/// TODO: expose from arps_wasm, remove this helper function
export function getDiAtDay(qi: number, qf: number, b: number, day: number): number {
  const t = AVG_DAYS_IN_YEAR / day;
  if (Math.abs(b) < EPSILON) {
    return -Math.log(qf / qi) * t;
  } else if (Math.abs(b - 1.0) < EPSILON) {
    return ((qi - qf) / qf) * t;
  } else {
    return ((Math.pow(qf / qi, -b) - 1.0) / b) * t;
  }
}

const roundTo6Decimal = (val) => {
  return Math.round(val * 1000000) / 1000000;
};

export function checkSegmentFlowRateValid(seg1: ArpSegment, seg2: ArpSegment) {
  if (seg1.di >= 0 && seg2.qf > seg1.qi) {
    throw "Invalid segment: qi cannot be less than qf.";
  }

  if (seg1.di < 0 && seg1.qi > seg2.qf) {
    throw "Invalid segment: negative decline requires qf to be greater than qi.";
  }
}

export function getPrimarySegmentEndDate(
  segments: ArpSegment[],
  kind: "Forecasting" | "TypeWell",
  cumulativeData: CumulativeData,
  segmentTemplate: SegmentTemplate
): Date | undefined {
  if (kind === "Forecasting" && cumulativeData?.PRIMARY_PRODUCT) {
    const primarySegments = segments.filter(
      (seg) => seg.product === cumulativeData.PRIMARY_PRODUCT
    );
    const lastSegment = primarySegments[primarySegments.length - 1];
    if (lastSegment && lastSegment.endDate) {
      return new Date(Date.parse(lastSegment.endDate));
    }
  }
  // Fallback for TypeWell: assume primary product is the first product added
  const index = segmentTemplate.numberOfSegments - 1;
  if (segments[index]?.endDate) {
    return new Date(Date.parse(segments[index].endDate));
  }
  return undefined;
}
