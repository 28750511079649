/**
 * Compares the current and updated records and returns an array of field names
 * that have changed, excluding a specified header.
 *
 * @param current - The current record with key-value pairs.
 * @param updated - The updated record with key-value pairs.
 * @param headerToIgnore - The header key to ignore during comparison.
 * @returns An array of field names that have changed.
 */
export const getNewCalculatedFields = (
  current: Record<string, unknown>,
  updated: Record<string, unknown>,
  headerToIgnore: string
): string[] => {
  const changedFields: string[] = [];
  const keys = new Set([...Object.keys(current), ...Object.keys(updated)]);
  keys.forEach((key) => {
    if (key === headerToIgnore) return;
    if (current[key]?.toString() !== updated[key]?.toString()) {
      changedFields.push(key);
    }
  });
  return changedFields;
};

/**
 * Merges the current and new calculated fields and returns a unique array of field names.
 *
 * @param previousCalculated - The current calculated fields.
 * @param newCalculatedFields - The new calculated fields.
 * @param header - The header key to ignore during comparison.
 * @returns A unique array of field names.
 */
export const mergeCalculatedFields = (
  previousCalculated: string[],
  newCalculatedFields: string[],
  header: string
): string[] => {
  return Array.from(
    new Set([...previousCalculated.filter((f) => f !== header), ...newCalculatedFields])
  );
};
